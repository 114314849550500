import Props from '@common/Props.interface'

export interface PageProps extends Props {
  HTML: string
}

const Html = ({ children, HTML, className = '' }: PageProps) => {
  return <div dangerouslySetInnerHTML={{ __html: HTML }} className={className} />
}

export default Html
